import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedLocationId: null,
    files: [[], [], []],
    legalQuery: null,
  },
  mutations: {
    setSelectedLocationId(state, locationId) {
      state.selectedLocationId = locationId;
    },
    setFiles(state, files) {
      state.files = files;
    },
    setLegalQuery(state, query) {
      state.legalQuery = query;
    },
  },
  actions: {
    updateStore({ commit }, payload) {
      commit("setSelectedLocationId", payload.selectedLocationId);
      commit("setFiles", payload.files);
      commit("setLegalQuery", payload.legalQuery);
    },
  },
});
